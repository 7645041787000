// eslint-disable-next-line
import 'vite/modulepreload-polyfill';

import {
    type Metric,
    onCLS,
    onFCP,
    onFID,
    onINP,
    onLCP,
    onTTFB,
} from 'web-vitals/attribution';

function sendToAnalytics(metric: Metric) {
    let location = window.location.pathname;

    // Just use the first part of the path to limit the metric
    // cardinality in prometheus.
    const parts = location.split('/');
    if (parts.length >= 2) {
        // eslint-disable-next-line
        location = parts[1];
    }

    if (!location.startsWith('/')) location = `/${location}`;

    const body = JSON.stringify({
        vital: { name: metric.name, value: metric.value },
        labels: {
            app: 'logistics-auth-proxy',
            view: location,
        },
    });

    const url = `${window.location.origin}/api/v1/metrics/vitals`;

    // Navigator has to be bound to ensure it does not error in some browsers
    // https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
    const blob = new Blob([body], { type: 'application/json' });
    const sendBeacon = navigator.sendBeacon.bind(navigator);
    try {
        sendBeacon(url, blob);
    } catch {
        // Before Chrome 81, there is a bug that cause type 'application/json' not to be allowed
        // as a type for sendBeacon. Therefore we fallback to fetch even though browser support is
        // similar (https://bugs.chromium.org/p/chromium/issues/detail?id=724929)
        fetch(url, { body: blob, method: 'POST', keepalive: true }).catch(
            (err) => console.error('Could not send analytics', err),
        );
    }
}

onCLS(sendToAnalytics);
onFCP(sendToAnalytics);
onFID(sendToAnalytics);
onINP(sendToAnalytics);
onLCP(sendToAnalytics);
onTTFB(sendToAnalytics);
